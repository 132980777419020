import { useTranslation } from "next-i18next";
import CustomFooter from "../footer/CustomFooter";
import CustomNavbar from "../navbar/CustomNavbar";
import { useRouter } from "next/router";

const PageLayout = ({
  children,
  navbarData,
  navbar,
  footer,
  navbarFooterData,
}) => {
  const { t } = useTranslation("home");
  const { locale } = useRouter();
  return (
    <>
      <a
        href={
          locale === "tr"
            ? "/iletisim/urunbasvuruformu"
            : "/en/contact-us/product-application-form"
        }
        className="btn-fixed"
      >
        {locale === "tr" ? "Sizi Arayalım" : "Let Us Call You"}
      </a>
      <CustomNavbar allMenu={navbarData} menu={navbar} />
      <main>{children}</main>
      <CustomFooter
        socialMedia={footer?.socialMedia}
        phones={footer?.phones}
        menu={navbarFooterData}
      />
    </>
  );
};

export default PageLayout;
