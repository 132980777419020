import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import logo from "../../public/logo.svg";
import { useRouter } from "next/router";
import { Fragment } from "react";
import AnchorLink from "../anchor/Anchor";

const CustomFooter = ({ socialMedia, phones, menu }) => {
  const { locale } = useRouter();
  const lang = locale == "en" ? 2 : 1;
  const { t } = useTranslation("home");
  const imageStyle = {
    marginLeft: 10
   
  }
  return (
    <footer className="footer">
      <div className="footer_top">
        <Container>
          <div className="footer_logo" style={{ display: "inline-block" }}>
            <Image src={logo.src} width={166} height={38} alt="işnet" />
          </div>
        </Container>
      </div>
      <Container style={{ marginBottom: "30px" }}>
        <Row>
          <Col sm={6} lg={3}>
            <ul className="footer_menu">
              <li className="footer_menu_item">
                <AnchorLink href="#" className="footer_menu_link">
                  <strong>{t("home:head_office")}</strong>
                </AnchorLink>
              </li>
              <li className={" footer_menu_item"}>
                <AnchorLink href="#" className="footer_menu_link">
                  <i className="icon-pin" />
                  İçmeler Mah. Piri Reis Cd. No: 62 Türkiye İş Bankası Tuzla
                  Teknoloji ve Operasyon Merkezi D Blok Tuzla/İstanbul
                </AnchorLink>
              </li>
              <li className={" footer_menu_item"}>
                <AnchorLink href="#" className="footer_menu_link">
                  <i className="icon-envelope-o" /> info@is.net.tr
                </AnchorLink>
              </li>
              <li className={" footer_menu_item"}>
                <AnchorLink href="#" className="footer_menu_link">
                  <i className="icon-envelope-o" /> efaturadestek@is.net.tr
                </AnchorLink>
              </li>
              <li className={" footer_menu_item"}>
                <AnchorLink href="#" className="footer_menu_link">
                  <i className="icon-envelope-o" /> nettearsiv@is.net.tr
                </AnchorLink>
              </li>
              <li className={" footer_menu_item"}>
                <AnchorLink href="#" className="footer_menu_link">
                  <i className="icon-envelope-o" /> lucanetdestek@is.net.tr
                </AnchorLink>
              </li>
            </ul>
          </Col>
          <Col sm={6} lg={6}>
            <ul className="footer_menu_links">
              {menu?.length > 0 &&
                menu?.map((item, index) => (
                  <Fragment key={index}>
                    {lang === item.languageId && item.inFooter && (
                      <li className="footer_menu_item" key={index}>
                        {item.href.slice(0, 4) === "http" ? (
                          <a
                            className="menu_sub_link"
                            target="_blank"
                            href={item.href ? `${item.href}` : "#"}
                          >
                            {item.name}
                          </a>
                        ) : (
                          <AnchorLink
                            locale={locale}
                            href={"/" + item.href}
                            className="footer_menu_link"
                          >
                            <i className="icon-angle-right" />
                            {item.name}
                          </AnchorLink>
                        )}
                      </li>
                    )}
                  </Fragment>
                ))}
            </ul>
          </Col>
          <Col md={12}></Col>
        </Row>
      </Container>
      <div className="footer_bottom">
        <Container>
          <div className="d-md-flex justify-content-between">
            <div className="col-md-9">
              {phones &&
                phones.map((phone, index) => (
                  <Fragment key={index}>
                    {locale === phone.lang ? (
                      <AnchorLink
                        href={`tel:${phone.tel}`}
                        className=" footer_phone"
                        key={index}
                      >
                        <small className="footer_phone_title">
                          {phone.title}
                        </small>
                        <p className="footer_phone_number">{phone.number}</p>
                        <i className="icon-icon-call" />
                      </AnchorLink>
                    ) : null}
                  </Fragment>
                ))}
              <AnchorLink href={`tel:08507243387`} className="footer_phone">
                <small className="footer_phone_title">
                  {lang === 1
                    ? "e-Fatura/Çırak/NetteArşiv/TreeWin/ NetteCap/ Belge.app Destek Hattı"
                    : "e-Fatura/Çırak/NetteArşiv/TreeWin/ NetteCap/ Belge.app Support"}
                </small>
                <p className="footer_phone_number">0850 724 33 87</p>
                <i className="icon-icon-call" />
              </AnchorLink>
              <AnchorLink href={`tel:08507245822`} className="footer_phone">
                <small className="footer_phone_title">
                  {lang === 1 ? "LucaNet Destek Hattı" : "LucaNet Support"}
                </small>
                <p className="footer_phone_number">0850 724 58 22</p>
                <i className="icon-icon-call" />
              </AnchorLink>
            </div>
            <div>
              <ul className="social_media_list">
                {socialMedia &&
                  socialMedia.map((item, index) => (
                    <li className="social_media_list_item" key={index}>
                      <AnchorLink
                        href={item.url}
                        className="social_media_list_link"
                        rel="nofollow"
                        target="_blank"
                      >
                        <i className={`icon-${item.icon}`} />
                      </AnchorLink>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className="footer_bot">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col sm={4}>
              <div className="align-items-center d-flex">
                <p
                  style={{
                    fontSize: "13px",
                    margin: 0,
                    paddingRight: "10px",
                   
                  }}
                >
                  Türkiye İş Bankası Kuruluşudur.
                </p>
                <a
                  href="https://www.isbank.com.tr/"
                  target="_blank"
                  rel="nofollow"
                  style={{ borderLeft: "1px solid #FFF"}}
                >
                  <Image
                    src="/isbankasi-logo.png"
                    alt="Is Bankasi"
                    height={50}
                    width={150}
                    unoptimized
                    style={ imageStyle}
                  />
                </a>
              </div>
            </Col>
            <Col sm={4} justify="flex-end">
              <div>
                <p className="text-end" style={{ margin: 0 }}>
                  {lang === 1
                    ? "Tüm hakları saklıdır. © İşNet AŞ" +
                      " " +
                      new Date().getFullYear()
                    : "All Right Reserved.© İşNet AŞ" +
                      " " +
                      new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default CustomFooter;
