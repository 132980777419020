import { useEffect, useState } from "react";

export const isExists = (value) => {
  let isExists = true;
  switch (value) {
    case null:
      isExists = false;
      break;
    case undefined:
      isExists = false;
      break;
    case "":
      isExists = false;
      break;
    case "undefined":
      isExists = false;
      break;
  }
  return isExists;
};

function isEmpty(obj) {
  if (
    obj === "" ||
    obj === null ||
    JSON.stringify(obj) === "{}" ||
    JSON.stringify(obj) === "[]" ||
    obj === undefined
  ) {
    return true;
  } else {
    return false;
  }
}

export function clean(values) {
  let object = Object.assign({}, values);
  if (typeof object !== "object") {
    return object;
  }
  let oKeys = Object.keys(object);
  for (let j = 0; j < oKeys.length; j++) {
    let p = oKeys[j];
    switch (typeof object[p]) {
      case "object":
        if (Array.isArray(object[p])) {
          let clonedValue = [...object[p]];
          for (let i = 0; i < clonedValue.length; i++) {
            clonedValue[i] = clean(clonedValue[i]);
            if (isEmpty(clonedValue[i])) {
              clonedValue.splice(i, 1);
              i--;
            }
          }
          if (clonedValue.length === 0) {
            if (Array.isArray(object)) {
              object.splice(parseInt(p), 1);
              j--;
            } else {
              delete object[p];
            }
          }
        } else {
          if (object[p] instanceof Date) break;

          if (isEmpty(object[p])) {
            try {
              delete object[p];
            } catch {
              break;
            }
          } else {
            object[p] = clean(object[p]);
            if (isEmpty(object[p])) delete object[p];
          }
        }
        break;
      default:
        if (isEmpty(object[p])) {
          delete object[p];
        }
        break;
    }
  }
  if (Object.keys(object).length === 0) {
    return;
  }
  return object;
}

export const fetcher = async (source) => {
  const response = await fetch(`${process.env.ISNETURL}/${source}`).then(
    (res) => res.json()
  );
  return response;
};

export const fetcherLocale = async (source) => {
  const response = await fetch(process.env.API_URL + "/" + source).then((res) =>
    res.json()
  );
  return response;
};

export function groupBy(list, keyGetter) {
  const map = new Map();
  list?.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByForDropdown(list, keyGetter) {
  const map = new Map();
  list?.forEach((item) => {
    const label = keyGetter(item);
    const collection = map.get(label);
    if (!collection) {
      map.set(label, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function staticPath(data) {
  const grouped = groupBy(data, (item) => item.parentId);

  data?.map((item) => {
    item.children = grouped.get(item.id) ?? null;
    if (item.children > 0) {
      item.children.map((element, index) => {
        const child = grouped.get(element.children[index].id);
        element.children[index].children = child ?? null;
      });
    }
  });
  const nav = data?.filter(
    (element) => element.parentId === undefined || element.parentId === null
  );

  return nav;
}

export const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};

export const Loader = () => <div>Loading...</div>;

export const contains = (arr1, mainObj, key) => {
  arr1.map((el) => console.log(el[key] in mainObj));
};
export const includes = (arr1, mainObj, key) => {
  const arr2 = arr1.filter((el) => el[key] in mainObj);
  return arr2.every((el) => el.categoryName === "e-Dönüşüm");
};

export const getLayoutData = async () => {
  const [navbarData, navbarFooterData, footerData] = await Promise.all([
    fetch(`${process.env.ISNETURL}/menu`).then((res) => res.json()),
    fetch(`${process.env.ISNETURL}/menu/footermenu`).then((res) => res.json()),
    fetch(`${process.env.API_URL}/footer`).then((res) => res.json()),
  ]);

  const navbarFilter =
    navbarData && navbarData?.filter((item) => item.isDeleted === false);

  let navbar, footer;
  if (navbarFilter || footerData) {
    const nav = staticPath(navbarFilter);
    navbar = nav;
    footer = footerData;
  }

  return {
    navbar,
    footer,
    navbarData,
    navbarFooterData,
  };
};

// Error formatting utility
export const formatError = (err) => {
  const baseError = {
    type: "Unknown Error",
    message: "An unknown error occurred",
    stack: null,
    timestamp: new Date().toISOString(),
    originalError: err, // Preserve original error for debugging
  };

  // Handle server response errors
  if (err?.type === "ServerError") {
    return {
      ...baseError,
      ...err,
    };
  }

  // Handle API response errors with isSuccessful flag
  if (err?.isSuccessful === false) {
    return {
      ...baseError,
      type: "ServerError",
      message: Array.isArray(err.errors)
        ? err.errors.join("\n")
        : err.errors || "Server returned an error",
      statusCode: err.statusCode,
      data: err,
    };
  }

  // Handle TypeError
  if (err instanceof TypeError) {
    return {
      ...baseError,
      type: "TypeError",
      message: err.message,
      stack: err.stack,
    };
  }

  // Handle standard Error objects
  if (err instanceof Error) {
    return {
      ...baseError,
      type: err.name,
      message: err.message,
      stack: err.stack,
    };
  }

  // If err is a string, use it as the message
  if (typeof err === "string") {
    return {
      ...baseError,
      message: err,
    };
  }

  return baseError;
};

// Helper to create readable error message for email
export const stringifyError = (err) => {
  const formattedError = formatError(err);

  // Format time in GMT+3
  const errorTime = new Date();
  const timeOptions = {
    timeZone: "Europe/Istanbul",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formattedTime =
    errorTime.toLocaleString("en-US", timeOptions) + " GMT+3";

  const errorDetails = {
    type: formattedError.type || err.type || "Unknown Error",
    message:
      formattedError.message || err.message || "An unknown error occurred",
    time: formattedTime,
    requestInfo: err.requestInfo || {},
    errorCause: {
      status:
        err.status ||
        err.statusCode ||
        (err.response && err.response.status) ||
        500,
      statusText:
        err.statusText ||
        (err.response && err.response.statusText) ||
        "Internal Server Error",
      responseText:
        err.responseText ||
        (err.response && err.response.data) ||
        "No response text available",
      stack: err.stack,
      response: err.response
        ? {
            status: err.response.status,
            statusText: err.response.statusText,
            data: err.response.data,
          }
        : undefined,
      requestData: err.requestData || {},
    },
  };

  return `
<div style="font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto;">
  <h2 style="color: #d32f2f; margin-bottom: 20px;">Error Report</h2>
  
  <table style="width: 100%; border-collapse: collapse; margin-top: 15px;">
    <tr><td style="color: #666; width: 120px;">Type:</td><td><strong>${
      errorDetails.type
    }</strong></td></tr>
    <tr><td style="color: #666;">Time:</td><td><strong>${
      errorDetails.time
    }</strong></td></tr>
    <tr><td style="color: #666;">Message:</td><td><strong>${
      errorDetails.message
    }</strong></td></tr>
    ${
      err.testScenario
        ? `<tr><td style="color: #666;">Test Mode:</td><td><strong>${err.testScenario}</strong></td></tr>`
        : ""
    }
  </table>

  <div style="margin-top: 20px;">
    <h3 style="color: #666; margin: 0 0 10px 0;">Error Response</h3>
    <pre style="background-color: #f5f5f5; padding: 15px; border-radius: 4px; overflow-x: auto; font-family: monospace; font-size: 13px; margin: 0;">
Status: ${errorDetails.errorCause.status}
Status Text: ${errorDetails.errorCause.statusText}
Response Text: ${errorDetails.errorCause.responseText}
${
  errorDetails.errorCause.response?.data
    ? `\nResponse Data: ${JSON.stringify(
        errorDetails.errorCause.response.data,
        null,
        2
      )}`
    : ""
}
${
  errorDetails.errorCause.response?.raw
    ? `\nRaw Response: ${JSON.stringify(
        errorDetails.errorCause.response.raw,
        null,
        2
      )}`
    : ""
}
    </pre>
  </div>

  ${
    errorDetails.requestInfo.url
      ? `
  <div style="margin-top: 20px;">
    <h3 style="color: #666; margin: 0 0 10px 0;">Request Information</h3>
    <pre style="background-color: #f5f5f5; padding: 15px; border-radius: 4px; overflow-x: auto; font-family: monospace; font-size: 13px; margin: 0;">
URL: ${errorDetails.requestInfo.url}
Method: ${errorDetails.requestInfo.method}
Headers: ${JSON.stringify(errorDetails.requestInfo.headers || {}, null, 2)}
${
  errorDetails.requestInfo.requestBody
    ? `\nRequest Body: ${JSON.stringify(
        errorDetails.requestInfo.requestBody,
        null,
        2
      )}`
    : ""
}
    </pre>
  </div>
  `
      : ""
  }

  ${
    errorDetails.errorCause.requestData &&
    Object.keys(errorDetails.errorCause.requestData).length > 0
      ? `
  <div style="margin-top: 20px;">
    <h3 style="color: #666; margin: 0 0 10px 0;">Request Data</h3>
    <pre style="background-color: #f5f5f5; padding: 15px; border-radius: 4px; overflow-x: auto; font-family: monospace; font-size: 13px; margin: 0;">
${Object.entries(errorDetails.errorCause.requestData)
  .map(
    ([key, value]) =>
      `${key}: ${
        typeof value === "object" ? JSON.stringify(value, null, 2) : value
      }`
  )
  .join("\n")}
    </pre>
  </div>
  `
      : ""
  }

  ${
    errorDetails.errorCause.stack
      ? `
  <div style="margin-top: 20px;">
    <h3 style="color: #666; margin: 0 0 10px 0;">Stack Trace</h3>
    <pre style="background-color: #f5f5f5; padding: 15px; border-radius: 4px; overflow-x: auto; font-family: monospace; font-size: 13px; margin: 0;">
${errorDetails.errorCause.stack}
    </pre>
  </div>
  `
      : ""
  }
</div>`;
};
